import React from "react"
import { getCourseNavigation } from "../../../../../store/courses"
import LayoutCourse from "../../../../../layouts/course"
import Box from "../../../../../components/box"
import Paragraph from "../../../../../components/paragraph"
import CourseFooterNext from "../../../../../components/course-footer-next"
import Heading from "../../../../../components/heading"
import Seo from "../../../../../components/seo"
import Stack from "../../../../../components/stack"
import Accent from "../../../../../components/accent"
import Grid from "../../../../../components/grid"
import MetricBox from "../../../../../components/metric-box"

const Page = () => {
  const navigation = getCourseNavigation({ courseId: "haelfte-des-lebens" })

  return (
    <LayoutCourse
      navigation={navigation}
      footer={
        <CourseFooterNext to="/kurse/haelfte-des-lebens/01-zwei-haelften/versmass-bestimmen/zusammenfassung-2" />
      }
    >
      <Seo title="Versmaß bestimmen" />
      <Box>
        <Stack>
          <Stack space={3}>
            <Heading as="h1" level={2}>
              Versmaß bestimmen
            </Heading>
          </Stack>
          <Paragraph>
            Was zunächst nach einem ziemlichen Chaos aus Strichen und
            Halbkreisen aussieht, lässt sich nach 4 grundlegenden griechischen
            Versmaßen gliedern:
          </Paragraph>
          <Grid columns={[1, 1, 2]} alignX="center">
            <MetricBox
              title="Trochäus"
              greek="τροχαῖος"
              greekNormalized="trochaios"
              metric="—◡"
            >
              <span>
                <Accent>Ro</Accent>sen, <Accent>Schwä</Accent>ne
              </span>
            </MetricBox>
            <MetricBox
              title="Jambus"
              greek="ἴαμβος"
              greekNormalized="ïambos"
              metric="◡—"
            >
              <span>
                Nat<Accent>ur</Accent>
              </span>
            </MetricBox>
            <MetricBox
              title="Daktylus"
              greek="δάκτυλος"
              greekNormalized="dáktylos"
              metric="—◡◡"
            >
              <span>
                <Accent>Hei</Accent>delberg, <Accent>Himm</Accent>lisches
              </span>
            </MetricBox>
            <MetricBox
              title="Anapäst"
              greek="ἀνάπαιστος"
              greekNormalized="anápaistos"
              metric="◡◡—"
            >
              <span>
                Poe<Accent>sie</Accent>
              </span>
            </MetricBox>
          </Grid>
          <Paragraph>
            Aus diesen Grundeinheiten wurden aber schon in der griechischen
            Antike komplexere metrische Folgen gebildet. Die griechische
            Sprache, in der die Silben nicht nach Betonungen, sondern nach
            Längen und Kürzen gemessen werden, ermöglichte dabei weit mehr
            Kombinationen als deutsche Sprache. Hölderlin sich jedoch immer
            wieder an Adaptionen versucht und – wie der Literaturwissenschaftler
            Wolfram Groddeck herausgearbeitet hat – auch in ›Hälfte des Lebens‹
            mit antiken metrischen Perioden experimentiert...
          </Paragraph>
        </Stack>
      </Box>
    </LayoutCourse>
  )
}

export default Page
